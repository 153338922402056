<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>

          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </div>
        <main v-if="is_API_loaded || isNewProduct" class="space-y-5">
          <!-- 1. -->
          <!-- 如果是新增商品，就隱藏此區塊 -->
          <div v-if="!isNewProduct" class="flex flex-wrap w-full items-center justify-between">
            <!-- 電腦版標題 -->
            <h1 class="text-xl font-medium text-gray-text_dark">{{ title }}</h1>

            <div class="flex items-center flex-shrink-0">
              <a :href="_wlink" target="_blank">
                <vs-button size="large" transparent>
                  <div class="flex items-center text-gray-text_light">
                    <svg-icon icon-class="eye_view" className="gray-text-light xl"></svg-icon>
                    <span>檢視</span>
                  </div>
                </vs-button>
              </a>
              <vs-button @click="copy_dialog = true" size="large" transparent>
                <div class="flex items-center space-x-1 text-gray-text_light">
                  <svg-icon icon-class="copy" className="gray-text-light"></svg-icon>
                  <span>複製</span>
                </div>
              </vs-button>
            </div>
          </div>

          <!-- 手機版標題 -->
          <!-- <h1 v-if="!isNewProduct" class="text-xl font-medium text-gray-text_dark lg:hidden">{{ title }}</h1> -->

          <!-- 新增商品後顯示 -->
          <transition name="fade">
            <section v-if="alertAfterCreated">
              <vs-alert color="success" gradient>
                <template #icon>
                  <svg-icon icon-class="success" className="xxl"></svg-icon>
                </template>
                <template #title> {{ alertTitle }} 已新增成功 </template>
                <a :href="_wlink" target="_blank">在前台中檢視</a> 或
                <router-link :to="{ name: 'menus' }">加入目錄選單</router-link>
              </vs-alert>
            </section>
          </transition>

          <!-- 2. -->
          <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
            <!-- 2.1.1 商品標題和內容-->
            <div class="w-full md:w-14/20 space-y-4 flex-shrink-0">
              <article class="box">
                <div>
                  <h1 class="title">標題 *</h1>
                  <ValidationProvider mode="aggressive" name="標題" rules="required|max:100" v-slot="{ errors }" tag="div" class="relative">
                    <vs-input @input="titleChange" class="w-full" v-model="title" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
                <div>
                  <h1 class="title">短描述</h1>
                  <h2 class="text-gray-400 text-xs mb-1">※短描述會呈現在商品內頁中</h2>

                  <ValidationProvider mode="aggressive" name="短描述" rules="max:500" v-slot="{ errors }" tag="div" class="relative">
                    <myTextarea height="100px" v-model="descs"></myTextarea>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
                <div>
                  <h1 class="title">內容</h1>
                  <editor ref="editor" v-model="content" :content="content" @input="contentChange"></editor>
                </div>
              </article>
              <!--  商品圖片 -->
              <article class="box">
                <h3 class="h3">圖片</h3>
                <draggable
                  tag="div"
                  class="grid grid-cols-2 sm:grid-cols-4 gap-4"
                  v-model="img_arr"
                  draggable=".image_wrap"
                  :animation="150"
                  dragClass="dragClass"
                  chosenClass="chosenClass"
                  filter=".unmover"
                  :forceFallback="true"
                >
                  <div v-for="(item, index) in img_arr" :key="index" class="image_wrap group">
                    <div class="image_func invisible group-hover:visible">
                      <button @click="img_arr.splice(index, 1)" title="刪除圖片" class="absolute top-2 right-2 hover:opacity-50 unmover">
                        <svg-icon icon-class="cancel" className="white"></svg-icon>
                      </button>
                      <div class="unmover">
                        <button title="預覽圖片" @click="previewImg(index)" class="hover:opacity-50">
                          <svg-icon icon-class="eye_view" className="white lg"></svg-icon>
                        </button>
                      </div>
                    </div>
                    <img class="w-full h-full object-contain" :src="item.img" :alt="item.alt" />
                  </div>
                  <div class="upload" slot="footer">
                    <div class="flex flex-col items-center">
                      <svg-icon icon-class="upload" className="gray-divide" style="width: 3rem; height: 3rem"></svg-icon>
                      <p class="text-gray-400 text-xs">點擊上傳或拖曳圖片</p>
                    </div>
                    <input
                      type="file"
                      multiple
                      @change="uploadImg"
                      accept="image/*"
                      class="border-2 absolute z-10 h-full w-full cursor-pointer opacity-0"
                    />
                  </div>
                </draggable>
              </article>

              <!--  庫存 1 -->
              <transition tag="div" name="fade">
                <article class="box" v-if="options">
                  <h3 class="h3">庫存</h3>
                  <div>
                    <h6 class="title">追蹤庫存數量</h6>
                    <vs-switch color="success" class="w-fit" v-model="trackingInventory"></vs-switch>
                  </div>
                </article>
              </transition>

              <!--  規格 -->
              <article class="box">
                <div class="flex items-center justify-between">
                  <h3 class="h3">規格</h3>
                  <vs-button size="mini" v-if="options" @click="options = false" transparent color="success">
                    <span class="text-sm">刪除所有商品規格</span>
                  </vs-button>
                  <vs-button size="mini" v-else @click="options = true" transparent color="success">
                    <span class="text-sm">新增商品規格</span>
                  </vs-button>
                </div>
                <div v-if="options" class="space-y-4">
                  <div v-for="(item, index1) in optionsTitle" :key="index1" class="flex items-start space-x-4">
                    <div class="w-1/3">
                      <h6 class="title">名稱</h6>
                      <ValidationProvider
                        mode="aggressive"
                        name="規格名稱"
                        rules="required|max:20"
                        v-slot="{ errors }"
                        tag="div"
                        class="relative"
                      >
                        <vs-input v-model="optionsTitle[index1]"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                    <div class="w-2/3">
                      <h6 class="title">選項</h6>
                      <div class="flex items-start space-x-2">
                        <ValidationProvider
                          name="規格選項"
                          mode="aggressive"
                          rules="max:20"
                          v-slot="{ errors }"
                          tag="div"
                          class="relative w-full"
                        >
                          <div class="flex flex-wrap items-center border border-gray-border rounded-xl overflow-hidden pr-2 w-full">
                            <div
                              v-for="(value, index2) in optionsValue[index1].values"
                              :key="index2"
                              class="flex items-center py-2 px-2 bg-gray-background rounded-md ml-2 mt-2 space-x-1"
                            >
                              <span>{{ value }}</span>
                              <button @click="deleteValue(index1, index2)">
                                <svg-icon icon-class="cancel" className="gray-border xs"></svg-icon>
                              </button>
                            </div>

                            <input
                              @blur="addValue(index1)"
                              @keypress.enter="addValue(index1)"
                              v-model="optionsValue[index1].input"
                              placeholder="點此輸入選項"
                              class="w-full px-2 h-10"
                            />
                          </div>
                          <errorDiv>{{ errors[0] }}</errorDiv>
                        </ValidationProvider>
                        <button @click="deleteOption(index1)" class="mt-2" title="刪除此規格">
                          <svg-icon icon-class="delete" className="gray-border"></svg-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- 新增更多規格按鈕 -->
                  <div v-if="optionsTitle.length < 3">
                    <vs-button @click="addOption" color="success" transparent class="flex items-center">
                      <svg-icon icon-class="add" className="success"></svg-icon>
                      <span class="ml-1">新增更多規格</span>
                    </vs-button>
                  </div>
                  <hr v-if="optionsList.length > 0" />
                  <!-- 規格細節 -->
                  <div v-if="optionsList.length > 0" class="flex items-center justify-between">
                    <h3 class="h3">規格細項</h3>
                    <vs-button transparent size="mini" color="success" @click="bathDialog = true">
                      <span class="text-sm">批次編輯</span>
                    </vs-button>
                  </div>
                  <!-- 細節標題 -->
                  <section v-if="optionsList.length > 0" class="w-full overflow-x-scroll space-y-4 pb-5">
                    <div class="flex items-center">
                      <p class="optionsDetail1 title">規格</p>
                      <transition name="fade">
                        <p v-if="trackingInventory" class="optionsDetail1 title">庫存</p>
                      </transition>
                      <p class="optionsDetail1 title">售價</p>
                      <p class="optionsDetail1 title">原價</p>
                      <p class="optionsDetail1 title">成本價</p>
                      <p class="optionsDetail2 title">商品貨號(SKU)</p>
                      <p class="optionsDetail2 title">商品條碼(Barcode)</p>
                      <p class="optionsDetail3 title">啟用</p>
                    </div>
                    <!-- 細節列表 -->
                    <div v-for="(list, index) in optionsList" :key="index">
                      <div class="flex items-center">
                        <!-- 規格 -->
                        <div class="optionsDetail1">
                          <span>{{ list.title }}</span>
                          <span v-if="list.arr2 && list.arr2.title"> / {{ list.arr2.title }}</span>
                          <span v-if="list.arr2 && list.arr2.arr3 && list.arr2.arr3.title"> / {{ list.arr2.arr3.title }}</span>
                        </div>
                        <!-- 庫存 -->
                        <transition name="fade">
                          <div v-if="trackingInventory" class="optionsDetail1">
                            <div class="flex items-center border border-gray-border rounded-xl h-10 px-3 overflow-hidden">
                              <input placeholder="0" type="number" class="w-full h-full" v-model="list.inventory" />
                            </div>
                          </div>
                        </transition>
                        <!-- 售價 -->
                        <div class="optionsDetail1">
                          <div class="flex items-center border border-gray-border rounded-xl px-3 space-x-1 overflow-hidden h-10">
                            <div>
                              <span>NT$</span>
                            </div>
                            <input placeholder="0" type="number" class="w-full h-full" v-model="list.salePrice" />
                          </div>
                        </div>
                        <!-- 原價 -->
                        <div class="optionsDetail1">
                          <div class="flex items-center border border-gray-border rounded-xl px-3 space-x-1 overflow-hidden h-10">
                            <div>
                              <span>NT$</span>
                            </div>
                            <input placeholder="0" type="number" class="w-full h-full" v-model="list.originalPrice" />
                          </div>
                        </div>
                        <!-- 成本價 -->
                        <div class="optionsDetail1">
                          <div class="flex items-center border border-gray-border rounded-xl px-3 space-x-1 overflow-hidden h-10">
                            <div>
                              <span>NT$</span>
                            </div>
                            <input placeholder="0" type="number" class="w-full h-full" v-model="list.costPrice" />
                          </div>
                        </div>
                        <!-- 商品貨號 -->
                        <div class="optionsDetail2">
                          <vs-input v-model="list.sku"></vs-input>
                        </div>
                        <!-- 商品條碼 -->
                        <div class="optionsDetail2">
                          <vs-input v-model="list.barcode"></vs-input>
                        </div>
                        <!-- 啟用 -->
                        <div class="optionsDetail3">
                          <vs-switch color="success" v-model="list.active" />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <p v-else>替商品新增多種不同的規格，例如：顏色、尺寸、材質。</p>
              </article>
              <!--  價格 -->
              <transition tag="div" name="fade">
                <article class="box" v-if="!options">
                  <h3 class="h3">價格</h3>
                  <div class="flex space-x-4">
                    <div class="w-full">
                      <h6 class="title">售價</h6>

                      <div class="flex items-center border border-gray-border rounded-xl pl-3 space-x-1 overflow-hidden h-10">
                        <div>
                          <span>NT$</span>
                        </div>
                        <input placeholder="0" type="number" class="w-full h-full" v-model.number="salePrice" />
                      </div>
                    </div>
                    <div class="w-full">
                      <h6 class="title">原價</h6>
                      <div class="flex items-center border border-gray-border rounded-xl pl-3 space-x-1 overflow-hidden h-10">
                        <div>
                          <span>NT$</span>
                        </div>
                        <input placeholder="0" type="number" class="w-full h-full" v-model.number="originalPrice" />
                      </div>
                    </div>
                    <div class="w-full">
                      <h6 class="title">成本價</h6>
                      <div class="flex items-center border border-gray-border rounded-xl pl-3 space-x-1 overflow-hidden h-10">
                        <div>
                          <span>NT$</span>
                        </div>
                        <input placeholder="0" type="number" class="w-full h-full" v-model.number="costPrice" />
                      </div>
                    </div>
                  </div>
                </article>
              </transition>

              <!--  庫存 2 -->
              <transition tag="div" name="fade">
                <article class="box" v-if="!options">
                  <h3 class="h3">庫存</h3>
                  <div class="flex space-x-4">
                    <div class="w-full">
                      <h6 class="title">商品貨號(SKU)</h6>
                      <vs-input v-model="sku"></vs-input>
                    </div>
                    <div class="w-full">
                      <h6 class="title">商品條碼(Barcode)</h6>
                      <vs-input v-model="barcode"></vs-input>
                    </div>
                  </div>
                  <div class="flex space-x-4">
                    <div class="w-full">
                      <h6 class="title">追蹤庫存數量</h6>
                      <vs-switch color="success" class="w-fit" v-model="trackingInventory"></vs-switch>
                    </div>
                    <div class="w-full" v-if="trackingInventory">
                      <h6 class="title">庫存數量</h6>
                      <div class="flex items-center border border-gray-border rounded-xl pl-3 space-x-1 overflow-hidden h-10">
                        <input placeholder="0" type="number" class="w-full h-full" v-model.number="inventory" />
                      </div>
                    </div>
                  </div>
                </article>
              </transition>

              <!--  SEO -->
              <article class="box overflow-hidden" :class="[seoSectionOpen ? '' : 'h-16']">
                <div class="flex items-center justify-between">
                  <h3 class="h3">SEO 搜尋引擎最佳化</h3>
                  <vs-button v-if="!seoSectionOpen" @click="seoSectionOpen = true" transparent color="success">編輯 SEO</vs-button>
                </div>

                <section
                  class="transition-transform duration-100 transform scale-y-0 origin-top space-y-6"
                  :class="[seoSectionOpen ? 'scale-y-100' : '']"
                >
                  <div class="space-y-1">
                    <h1 class="text-success font-medium text-base">{{ seoTitle }}</h1>
                    <p class="text-green-700 text-sm break-all" v-if="seoUrl">{{ _seolink }}{{ seoUrl }}</p>
                    <p class="text-sm text-limit-2" style="max-height: 2.5em">{{ _seoContent }}</p>
                  </div>
                  <div>
                    <h1 class="title">網頁標題</h1>
                    <ValidationProvider mode="aggressive" name="網頁標題" rules="max:100" v-slot="{ errors }">
                      <vs-input class="w-full" v-model="seoTitle" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>

                  <div>
                    <h1 class="title">網頁網址</h1>
                    <div class="flex items-center border border-gray-border rounded-xl pl-3">
                      <div>
                        <span class="whitespace-nowrap">{{ _seolink }}</span>
                      </div>
                      <ValidationProvider class="w-full" mode="aggressive" name="網頁網址" rules="max:100" v-slot="{ errors }">
                        <vs-input id="noBorderInput" class="w-full" v-model.trim="seoUrl" />
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div>
                    <h1 class="title">網頁描述</h1>
                    <myTextarea autoHeight v-model="_seoContent"></myTextarea>
                  </div>
                </section>
              </article>
            </div>

            <!-- 2.2 -->
            <div class="flex-grow space-y-4">
              <div class="box">
                <h3 class="h3">公開狀態</h3>
                <div class="flex flex-col items-start space-y-2">
                  <vs-radio v-model="isPublic" :val="1">公開</vs-radio>
                  <vs-radio v-model="isPublic" :val="0">隱藏<span v-if="isSchedule"> (已排程)</span></vs-radio>
                </div>
                <div key="1" v-if="!isPublic" class="flex flex-col items-end space-y-6">
                  <vs-button key="2" v-if="!schedule" @click="setSchedule" transparent color="success"> 設定排程 </vs-button>

                  <div key="3" v-if="schedule" class="space-y-6 pt-6 w-full">
                    <vs-input type="date" :min="_minDate" :max="_maxDate" v-model="date" label="公開日期" />
                    <vs-input type="time" v-model="time" label="公開時間" />
                  </div>
                  <vs-button key="4" v-if="schedule" @click="cancelSchedule" transparent color="success">取消排程</vs-button>
                </div>
              </div>
              <div class="box">
                <div class="flex items-center justify-between">
                  <h3 class="h3">分類</h3>
                  <vs-tooltip top shadow not-hover v-model="collectionTooltip">
                    <vs-button
                      size="mini"
                      @click="collectionTooltip = !collectionTooltip"
                      color="success"
                      transparent
                      class="flex items-center"
                    >
                      <svg-icon icon-class="add" className="success xs"></svg-icon>
                      <span class="ml-1 text-sm">建立新分類</span>
                    </vs-button>

                    <template #tooltip>
                      <div class="flex items-center justify-between space-x-2">
                        <vs-input v-model="newCollectionTitle" placeholder="請輸入分類標題"></vs-input>
                        <vs-button ref="newCollectionBtn" @click="newCollection" size="large">新增</vs-button>
                      </div>
                    </template>
                  </vs-tooltip>
                </div>
                <transition-group tag="div" name="fade">
                  <button
                    key="0"
                    v-if="!collectionsToggle"
                    @click="collectionsToggle = true"
                    class="flex flex-wrap items-center border border-gray-border rounded-xl pl-3 space-x-1 overflow-hidden w-full h-10"
                  >
                    <p style="color: rgba(156, 163, 175, var(--tw-text-opacity))">選擇分類</p>
                  </button>
                  <div v-if="!collectionsToggle" key="1" class="flex flex-wrap">
                    <div
                      v-for="item in _collections"
                      v-if="selectedCollections.includes(item.sptno)"
                      :key="item.sptno"
                      class="flex items-center py-2 px-2 bg-gray-background rounded-md ml-2 mt-2 space-x-1 w-fit"
                    >
                      <span>{{ item.title }}</span>
                      <button @click="deleteCollectionTag(item)">
                        <svg-icon icon-class="cancel" className="gray-border xs"></svg-icon>
                      </button>
                    </div>
                  </div>
                </transition-group>
                <transition tag="div" name="fade">
                  <!-- 分類選項列表 -->
                  <ul v-if="collections.length > 0 && collectionsToggle" class="flex flex-col">
                    <li v-for="arr1 in collections" :key="arr1.sptno">
                      <vs-checkbox :val="arr1.sptno" v-model="selectedCollections" @change="checkbox(arr1, $event, arr1)">
                        {{ arr1.title }}
                      </vs-checkbox>
                      <ul v-if="arr1.arr.length > 0" class="collectionsLevel2">
                        <li v-for="arr2 in arr1.arr" :key="arr2.sptno">
                          <vs-checkbox @change="checkbox(arr2, $event, arr1)" :val="arr2.sptno" v-model="selectedCollections">
                            {{ arr2.title }}
                          </vs-checkbox>
                          <ul v-if="arr2.arr.length > 0" class="collectionsLevel3">
                            <li v-for="arr3 in arr2.arr" :key="arr3.sptno">
                              <vs-checkbox @change="checkbox(arr3, $event, arr1, arr2)" :val="arr3.sptno" v-model="selectedCollections">
                                {{ arr3.title }}
                              </vs-checkbox>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <div class="flex items-center space-x-4 self-end mt-2">
                      <vs-button color="success" size="large" @click="collectionsToggle = false">
                        <span class="font-medium">確定</span>
                      </vs-button>
                    </div>
                  </ul>
                </transition>
              </div>
              <div class="box">
                <div class="flex items-center justify-between">
                  <h3 class="h3">標籤</h3>

                  <vs-tooltip top shadow not-hover v-model="tagTooltip">
                    <vs-button size="mini" @click="tagTooltip = !tagTooltip" color="success" transparent class="flex items-center">
                      <svg-icon icon-class="add" className="success xs"></svg-icon>
                      <span class="ml-1 text-sm">建立新標籤</span>
                    </vs-button>

                    <template #tooltip>
                      <div class="flex items-center justify-between space-x-2">
                        <vs-input v-model="newTagTitle" placeholder="請輸入標籤名稱"></vs-input>
                        <vs-button ref="newTagBtn" @click="newTag" size="large">新增</vs-button>
                      </div>
                    </template>
                  </vs-tooltip>
                </div>
                <vs-select v-if="tags.length > 0" filter multiple placeholder="搜尋標籤" v-model="selectedTags">
                  <vs-option v-for="tag in tags" :label="tag.title" :value="tag.sptno" :key="tag.sptno">{{ tag.title }}</vs-option>
                </vs-select>
              </div>
              <div class="box">
                <div class="flex items-center justify-between">
                  <h3 class="h3">供應商</h3>

                  <vs-tooltip top shadow not-hover v-model="vendorTooltip">
                    <vs-button size="mini" @click="vendorTooltip = !vendorTooltip" color="success" transparent class="flex items-center">
                      <svg-icon icon-class="add" className="success xs"></svg-icon>
                      <span class="ml-1 text-sm">建立新供應商</span>
                    </vs-button>

                    <template #tooltip>
                      <div class="flex items-center justify-between space-x-2">
                        <vs-input v-model="newVendorTitle" placeholder="請輸入供應商名稱"></vs-input>
                        <vs-button ref="newVendorBtn" @click="newVendor" size="large">新增</vs-button>
                      </div>
                    </template>
                  </vs-tooltip>
                </div>
                <vs-select v-if="vendors.length > 0" filter multiple placeholder="搜尋供應商" v-model="selectedVendors">
                  <vs-option v-for="vendor in vendors" :label="vendor.title" :value="vendor.spmno" :key="vendor.spmno">{{
                    vendor.title
                  }}</vs-option>
                </vs-select>
              </div>
            </div>
          </section>

          <hr />

          <section class="flex items-center justify-between">
            <vs-button @click="deleteProduct_dialog = true" v-if="!isNewProduct" size="large" color="danger" border>
              <span class="font-medium">刪除商品</span>
            </vs-button>
            <vs-button to="/admin/pages" v-else size="large" border>
              <span class="font-medium">取消</span>
            </vs-button>
            <vs-button size="large" ref="saveProduct" :disabled="invalid" @click="saveProduct(invalid)">
              <span class="font-medium">儲存商品</span>
            </vs-button>
          </section>
        </main>
      </div>
    </ValidationObserver>

    <!-- 骨架屏 -->
    <main v-if="!is_API_loaded && !isNewProduct" class="router-view-container space-y-4">
      <section class="flex items-center space-x-4 justify-between">
        <skeleton width="48px" />

        <div v-if="!isNewProduct" class="flex lg:w-full items-center justify-between">
          <skeleton class="hidden lg:block" width="50%" height="40px" />
          <div class="flex items-center space-x-2">
            <skeleton width="82px" />
            <skeleton width="82px" />
          </div>
        </div>
      </section>
      <skeleton v-if="!isNewProduct" class="lg:hidden" width="80%" height="40px" />

      <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
        <div class="w-full md:w-14/20 space-y-4">
          <article class="box">
            <skeleton />

            <div>
              <skeleton height="200px" />
            </div>
          </article>

          <article class="box">
            <skeleton />
          </article>
        </div>

        <div class="flex-grow space-y-4">
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
        </div>
      </section>

      <hr />

      <section class="flex items-center justify-between">
        <skeleton width="82px" />
        <skeleton width="82px" />
      </section>
    </main>

    <!-- 刪除商品 dialog -->
    <vs-dialog overflow-hidden blur v-model="deleteProduct_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除此商品？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的商品無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="deleteProduct_dialog = false">取消</vs-button>
          <vs-button @click="deleteProduct" size="large" transparent color="danger" ref="deleteProductBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>

    <!-- 複製商品 dialog -->
    <vs-dialog overflow-hidden blur v-model="copy_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要複製此商品？</h1>
      </template>
      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="copy_dialog = false">取消</vs-button>
          <vs-button @click="copy" size="large" transparent ref="copyBtn" color="success">複製</vs-button>
        </div>
      </template>
    </vs-dialog>

    <!-- 預覽圖片 dialog -->
    <vs-dialog @close="previewClose" overflow-hidden full-screen v-model="previewDialog">
      <div v-if="previewIndex >= 0" class="w-full h-full flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
        <div class="w-full h-full sm:w-8/12">
          <img class="h-full w-full object-contain" :src="img_arr[previewIndex].img" />
        </div>

        <ValidationObserver v-slot="{ invalid }" tag="div" class="flex-grow flex flex-col items-start justify-center">
          <h6 class="title w-full">更新圖片替代文字(ALT)</h6>
          <ValidationProvider
            mode="aggressive"
            name="圖片替代文字"
            rules="max:100"
            v-slot="{ errors }"
            tag="div"
            class="relative w-full mb-4"
          >
            <vs-input v-model="alt"></vs-input>
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>
          <div class="flex items-center space-x-4 self-end">
            <vs-button @click=";(previewDialog = false), (alt = '')" transparent size="large">
              <span class="font-medium">取消</span>
            </vs-button>
            <vs-button color="success" size="large" :disabled="invalid" @click="saveALT(invalid)">
              <span class="font-medium">確定</span>
            </vs-button>
          </div>
        </ValidationObserver>
      </div>
    </vs-dialog>

    <!-- 批次編輯規格 dialog -->
    <vs-dialog overflow-hidden blur v-model="bathDialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">批次編輯</h1>
      </template>

      <section class="flex flex-col space-y-2 text-gray-text_dark text-sm">
        <div class="flex items-center space-x-2 justify-between">
          <vs-checkbox success v-model="bathCheckbox.inventory">庫存</vs-checkbox>
          <div class="flex items-center border border-gray-border rounded-xl h-10 px-3 overflow-hidden w-32 text-gray-text_light">
            <input placeholder="0" type="number" class="w-full h-full" v-model="bathInput.inventory" />
          </div>
        </div>
        <div class="flex items-center space-x-2 justify-between">
          <vs-checkbox success v-model="bathCheckbox.salePrice">售價</vs-checkbox>
          <div class="flex items-center border border-gray-border rounded-xl px-3 space-x-1 overflow-hidden h-10 w-32 text-gray-text_light">
            <div>
              <span>NT$</span>
            </div>
            <input placeholder="0" type="number" class="w-full h-full" v-model="bathInput.salePrice" />
          </div>
        </div>
        <div class="flex items-center space-x-2 justify-between">
          <vs-checkbox success v-model="bathCheckbox.originalPrice">原價</vs-checkbox>
          <div class="flex items-center border border-gray-border rounded-xl px-3 space-x-1 overflow-hidden h-10 w-32 text-gray-text_light">
            <div>
              <span>NT$</span>
            </div>
            <input placeholder="0" type="number" class="w-full h-full" v-model="bathInput.originalPrice" />
          </div>
        </div>
        <div class="flex items-center space-x-2 justify-between">
          <vs-checkbox success v-model="bathCheckbox.costPrice">成本價</vs-checkbox>
          <div class="flex items-center border border-gray-border rounded-xl px-3 space-x-1 overflow-hidden h-10 w-32 text-gray-text_light">
            <div>
              <span>NT$</span>
            </div>
            <input placeholder="0" type="number" class="w-full h-full" v-model="bathInput.costPrice" />
          </div>
        </div>
        <div class="flex items-center space-x-2 justify-between">
          <vs-checkbox success v-model="bathCheckbox.sku">商品貨號(SKU)</vs-checkbox>
          <div class="flex items-center border border-gray-border rounded-xl h-10 px-3 overflow-hidden w-32 text-gray-text_light">
            <input class="w-full h-full" v-model="bathInput.sku" />
          </div>
        </div>
        <div class="flex items-center space-x-2 justify-between">
          <vs-checkbox success v-model="bathCheckbox.barcode">商品條碼(Barcode)</vs-checkbox>
          <div class="flex items-center border border-gray-border rounded-xl h-10 px-3 overflow-hidden w-32 text-gray-text_light">
            <input class="w-full h-full" v-model="bathInput.barcode" />
          </div>
        </div>
        <div class="flex items-center space-x-2 justify-between">
          <vs-checkbox success v-model="bathCheckbox.active">啟用</vs-checkbox>
          <vs-switch color="success" v-model="bathInput.active" />
        </div>
      </section>

      <template #footer>
        <p class="text-right text-xs text-gray-400 mb-2">※ 只會更新已被勾選的規格。</p>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="bathDialog = false">取消</vs-button>
          <vs-button @click="bathEdit" size="large" transparent color="success">確定</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import editor from '@/components/editor.vue'
export default {
  name: 'product',
  components: {
    editor,
    draggable,
  },
  data() {
    return {
      is_API_loaded: false,
      isNewProduct: false,
      title: '',
      descs: '',
      content: '',
      seoTitle: '',
      seoUrl: '',
      seoContent: '',
      seoSectionOpen: false,
      titleSync: true,
      urlSync: true,
      contentSync: true,
      isPublic: 1,
      isSchedule: false, // 判斷此商品有沒有排程
      schedule: false, // 設定排程按鈕
      date: '2000-01-01',
      time: '23:59',
      deleteProduct_dialog: false,
      alertAfterCreated: false, // 新增成功後呈現alert訊息
      alertTitle: '',
      copy_dialog: false, // 複製dialog
      wlink: '',
      // 價格
      salePrice: '', //售價
      originalPrice: '', // 原價
      costPrice: '', // 成本
      // 規格
      options: false, // 有沒有多種規格
      optionsTitle: [], // 規格名稱
      optionsValue: [], // 規格選項
      // 規格細項
      optionsList: [],
      bathDialog: false, // 批次修改細項 dialog
      bathCheckbox: {
        // 批次編輯，checkbox有打勾才修改
        inventory: false, // 庫存
        salePrice: false, // 售價
        originalPrice: false, // 原價
        costPrice: false, // 成本價
        sku: false, // 商品貨號
        barcode: false, // 商品條碼
        active: false,
      },
      bathInput: {
        inventory: '', // 庫存
        salePrice: '', // 售價
        originalPrice: '', // 原價
        costPrice: '', // 成本價
        sku: '', // 商品貨號
        barcode: '', // 商品條碼
        active: false,
      },
      // 庫存
      sku: '',
      barcode: '',
      trackingInventory: false,
      inventory: '',
      // 分類
      collections: [],
      selectedCollections: [], // v-model
      selectedCollectionsArr: [], // json
      collectionTooltip: false,
      newCollectionTitle: '',
      collectionsToggle: false,
      // 標籤
      tags: [],
      selectedTags: [],
      tagTooltip: false,
      newTagTitle: '',
      // 供應商
      vendors: [],
      selectedVendors: [],
      vendorTooltip: false,
      newVendorTitle: '',
      // 圖片列表
      img_arr: [],
      previewIndex: undefined, // 預覽圖片的索引
      alt: '',
      previewDialog: false, // dialog預覽
    }
  },
  computed: {
    _dateTime() {
      if (this.isPublic || this.schedule) {
        return this.date + ' ' + this.time + ':00'
      } else {
        return '2099-12-31' + ' ' + '23:59' + ':59'
      }
    },
    _minDate() {
      // 可選擇的最小日期
      const t = new Date()

      let year = t.getFullYear()
      let month = t.getMonth() + 1
      let date = t.getDate()
      if (date < 10) date = `0${date}`
      if (month < 10) month = `0${month}`
      return `${year}-${month}-${date}`
    },
    _maxDate() {
      // 可選擇的最大日期
      let now = Date.now() + 1000 * 60 * 60 * 24 * 365 * 10 // 獲取現在毫秒數 + 10年

      const t = new Date(now)

      let year = t.getFullYear()
      let month = t.getMonth() + 1
      let date = t.getDate()
      if (date < 10) date = `0${date}`
      if (month < 10) month = `0${month}`
      return `${year}-${month}-${date}`
    },
    _seoContent: {
      get: function () {
        return this.seoContent
          .replace(/<[^>]+>/g, '') // html標籤去除
          .replace(/&nbsp;/g, ' ') // &nbsp;轉空白
          .substr(0, 200) // 限制200字
      },
      set: function (val) {
        this.seoContent = val
      },
    },
    _wlink() {
      return this.$store.state.getWebData.param.wlink + this.wlink
    },
    _seolink() {
      if (this.isNewProduct) return this._wlink + '/products/'
      if (!this.isNewProduct) {
        let i = this._wlink.lastIndexOf('/') // 最後一個/的索引
        return this._wlink.substr(0, i + 1)
      }
    },
    _collections() {
      let flatArr = []
      this.collections.forEach((arr1) => {
        flatArr.push({
          sptno: arr1.sptno,
          title: arr1.title,
        })

        arr1.arr.forEach((arr2) => {
          flatArr.push({
            sptno: arr2.sptno,
            title: arr2.title,
          })

          arr2.arr.forEach((arr3) => {
            flatArr.push({
              sptno: arr3.sptno,
              title: arr3.title,
            })
          })
        })
      })

      return flatArr
    },
  },
  watch: {
    $route(to, from) {
      this.isNewProductFunc()
    },
    seoTitle(val) {
      if (val !== this.title) this.titleSync = false
      if (val === '') {
        // this.seoTitle = this.title
        this.titleSync = true
      }
    },
    seoUrl(val) {
      if (val !== this.title || this.isNewProduct === false) this.urlSync = false
      if (val === '') {
        // this.seoUrl = this.title
        this.urlSync = true
      }
    },
    seoContent(val) {
      if (val !== this.content) this.contentSync = false
      if (val === '') {
        // this.seoContent = this.content
        this.contentSync = true
      }
    },
    isPublic(val) {
      if (val) {
        this.date = '2000-01-01'
        this.time = '23:59'
        this.schedule = false
        this.isSchedule = false
      }
    },
    // 第一次點選新增產品規格，預設增加第一筆規格。
    options(val) {
      if (val && this.optionsTitle.length === 0) {
        this.addOption()
      }
    },
  },
  async created() {
    // 讀取初始資料
    const res = await Promise.all([this.getCollections(), this.getTagList(), this.getVendorList(), this.isNewProductFunc()])

    // res會是陣列，若都為true，才顯示頁面
    if (res.every((e) => e === true)) {
      // this.isNewProductFunc()
      this.is_API_loaded = true
    }
  },
  methods: {
    async isNewProductFunc() {
      // 判斷是新增商品 還是 編輯商品
      // this.$route.name === 'new-product' ? (this.isNewProduct = true) : (this.isNewProduct = false)
      if (this.$route.name === 'new-product') {
        this.isNewProduct = true
        return true
      } else {
        this.isNewProduct = false
        let result = await this.loadProduct()
        return result
      }
    },
    titleChange(value) {
      // 若sync = true，賦值到seo
      if (this.titleSync) this.seoTitle = value
      if (this.urlSync) this.seoUrl = value
    },
    contentChange(value) {
      // 若sync = true，賦值到seo
      if (this.contentSync) this.seoContent = value
    },
    // 設定排程 取得現在時間
    setSchedule() {
      this.schedule = true

      let now = Date.now() + 1000 * 60 * 30 // 獲取現在毫秒數 + 30分鐘

      const t = new Date(now)

      let year = t.getFullYear()
      let month = t.getMonth() + 1
      let date = t.getDate()
      let hour = t.getHours()
      let min = t.getMinutes()

      if (date < 10) date = `0${date}`
      if (month < 10) month = `0${month}`
      if (min < 10) min = `0${min}`

      this.date = `${year}-${month}-${date}`
      this.time = `${hour}:${min}`
    },
    // 取消排程
    cancelSchedule() {
      this.schedule = false
      this.isSchedule = false
      this.date = '2099-12-31'
      this.time = '23:59'
    },
    // 3.1.1.2 商品-讀取
    loadProduct() {
      return this.$axios({
        url: 'front/store/product/getProductUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.optionsTitle = [] // 規格名稱
          this.optionsValue = [] // 規格選項
          this.optionsList = [] // 規格細項
          // console.log('讀取商品', res.data.Data)
          // this.is_API_loaded = true
          let data = res.data.Data

          // 時間
          let dt = data.rtime.split(' ')
          this.date = dt[0]
          this.time = dt[1]

          // 若有排程，設定的時間預設打開
          if (data.isrtime === 0 && dt[0] !== '2099-12-31') {
            this.isSchedule = true
            this.schedule = true
          }

          this.isPublic = data.isrtime
          this.title = data.title
          this.descs = data.descs
          this.content = data.cont
          this.seoTitle = data.mtitle
          this.seoContent = data.mdescs
          this.seoUrl = data.mpages
          this.wlink = data.wlink

          this.img_arr = data.img_arr

          let tag_arr = []
          data.tag_arr.forEach((item) => tag_arr.push(item.sptno))
          this.selectedTags = tag_arr

          let manu_arr = []
          data.manu_arr.forEach((item) => manu_arr.push(item.spmno))
          this.selectedVendors = manu_arr

          this.selectedCollectionsArr = data.type_arr

          let selectedCollections = []
          data.type_arr.forEach((arr1) => {
            selectedCollections.push(arr1.sptno)
            arr1.arr.forEach((arr2) => {
              selectedCollections.push(arr2.sptno)
              arr2.arr.forEach((arr3) => {
                selectedCollections.push(arr3.sptno)
              })
            })
          })
          this.selectedCollections = selectedCollections

          // 若規格一的選項有值
          if (data.spec_arr1.length > 0) {
            this.options = true // 若有規格選項，打開
            let valuesArr = []
            data.spec_arr1.forEach((item) => valuesArr.push(item.spec)) // 抓出spec_arr1的spec，放進values
            // 新增到選項列表
            this.optionsValue.push({
              input: '',
              values: valuesArr,
            })
          }
          // 若規格二的選項有值
          if (data.spec_arr2.length > 0) {
            let valuesArr = []
            data.spec_arr2.forEach((item) => valuesArr.push(item.spec)) // 抓出spec_arr2的spec，放進values
            // 新增到選項列表
            this.optionsValue.push({
              input: '',
              values: valuesArr,
            })
          }
          // 若規格三的選項有值
          if (data.spec_arr3.length > 0) {
            let valuesArr = []
            data.spec_arr3.forEach((item) => valuesArr.push(item.spec)) // 抓出spec_arr3的spec，放進values
            // 新增到選項列表
            this.optionsValue.push({
              input: '',
              values: valuesArr,
            })
          }
          // 規格細項列表
          data.spec_item.forEach((item) => {
            // 若有一/二/三層
            if (item.spec1 && item.spec2 && item.spec3) {
              this.optionsList.push({
                title: item.spec1,
                inventory: item.snum, // 庫存
                salePrice: item.sprice, // 售價
                originalPrice: item.price, // 原價
                costPrice: item.cost, // 成本價
                sku: item.sku, // 商品貨號
                barcode: item.code, // 商品條碼
                active: item.isenable ? true : false,
                arr2: {
                  title: item.spec2,
                  arr3: {
                    title: item.spec3,
                  },
                },
              })
            } else if (item.spec1 && item.spec2) {
              // 若有一/二層
              this.optionsList.push({
                title: item.spec1,
                inventory: item.snum, // 庫存
                salePrice: item.sprice, // 售價
                originalPrice: item.price, // 原價
                costPrice: item.cost, // 成本價
                sku: item.sku, // 商品貨號
                barcode: item.code, // 商品條碼
                active: item.isenable ? true : false,
                arr2: {
                  title: item.spec2,
                  arr3: {},
                },
              })
            } else if (item.spec1) {
              // 若有一層
              this.optionsList.push({
                title: item.spec1,
                inventory: item.snum, // 庫存
                salePrice: item.sprice, // 售價
                originalPrice: item.price, // 原價
                costPrice: item.cost, // 成本價
                sku: item.sku, // 商品貨號
                barcode: item.code, // 商品條碼
                active: item.isenable ? true : false,
                arr2: {},
              })
            } else {
              // 若都沒有
              this.originalPrice = item.price
              this.salePrice = item.sprice
              this.sku = item.sku
              this.barcode = item.code
              this.inventory = item.snum
              this.costPrice = item.cost
            }
          })
          // 規格名稱
          if (data.spec_name1) this.optionsTitle.push(data.spec_name1)
          if (data.spec_name2) this.optionsTitle.push(data.spec_name2)
          if (data.spec_name3) this.optionsTitle.push(data.spec_name3)
          // 庫存追蹤
          this.trackingInventory = data.stock_track ? true : false
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        return res.data.Success
      })
    },
    // 3.1.1.3 商品-新增
    // 3.1.1.4 商品-修改
    saveProduct(invalid) {
      this.alertAfterCreated = false
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.saveProduct,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let url = '' // api url

      let mdescs = '' // seo網頁描述
      if (this._seoContent === '') {
        // 若seo網頁描述被清空，把內容塞回去
        mdescs = this.content
          .replace(/<[^>]+>/g, '') // html標籤去除
          .replace(/&nbsp;/g, ' ') // &nbsp;轉空白
          .substr(0, 200) // 限制200字
      } else {
        mdescs = this._seoContent
      }

      let mpages = '' // 網頁網址
      if (this.seoUrl === '') {
        mpages = this.title.replace(/[&|\*^'"{}[\]%$:;=#@!/?\s*\\\\<>,.()~+_`]/g, '-')
      } else {
        mpages = this.seoUrl.replace(/[&|\*^'"{}[\]%$:;=#@!/?\s*\\\\<>,.()~+_`]/g, '-')
      }
      let spec_arr1 = [] // 選項1
      if (this.optionsValue.length > 0) {
        this.optionsValue[0].values.forEach((item) => {
          spec_arr1.push({
            spec: item,
          })
        })
      }

      let spec_arr2 = [] // 選項2
      if (this.optionsValue.length > 1) {
        this.optionsValue[1].values.forEach((item) => {
          spec_arr2.push({
            spec: item,
          })
        })
      }

      let spec_arr3 = [] // 選項3
      if (this.optionsValue.length > 2) {
        this.optionsValue[2].values.forEach((item) => {
          spec_arr3.push({
            spec: item,
          })
        })
      }
      let spec_item = [] // 規格項目 json
      if (this.optionsList.length === 0) {
        // 若沒有規格
        spec_item.push({
          sku: this.sku,
          code: this.barcode,
          spec1: '',
          spec2: '',
          spec3: '',
          cost: this.costPrice,
          price: this.originalPrice,
          sprice: this.salePrice,
          snum: this.inventory,
          isenable: 1,
        })
      } else {
        // 有規格
        this.optionsList.forEach((item) => {
          let spec2 = "";
          if(item.arr2.title != undefined) spec2 = item.arr2.title;
          let spec3 = "";
          if(item.arr2.title != undefined) spec3 = item.arr2.arr3.title;
          spec_item.push({
            sku: item.sku,
            code: item.barcode,
            spec1: item.title,
            spec2: spec2,
            spec3: spec3,
            cost: item.costPrice,
            price: item.originalPrice,
            sprice: item.salePrice,
            snum: item.inventory,
            isenable: item.active ? 1 : 0,
          })
        })
      }
      let tag_arr = [] // 標籤 json
      this.selectedTags.forEach((item) => {
        tag_arr.push({
          sptno: item,
        })
      })

      let manu_arr = [] // 供應商 json
      this.selectedVendors.forEach((item) => {
        manu_arr.push({
          spmno: item,
        })
      })
      let data = {
        lang: 0,
        rtime: this._dateTime,
        title: this.title,
        descs: this.descs,
        cont: this.content,
        mtitle: this.seoTitle ? this.seoTitle : (this.seoTitle = this.title),
        mdescs: mdescs,
        mpages: mpages,
        spec_name1: this.optionsTitle[0] ? this.optionsTitle[0] : '', // 規格標題1
        spec_name2: this.optionsTitle[1] ? this.optionsTitle[1] : '', // 規格標題2
        spec_name3: this.optionsTitle[2] ? this.optionsTitle[2] : '', // 規格標題3
        stock_track: this.trackingInventory ? 1 : 0, // 庫存追蹤
        spec_arr1: JSON.stringify(spec_arr1), // 選項1 json
        spec_arr2: JSON.stringify(spec_arr2), // 選項2 json
        spec_arr3: JSON.stringify(spec_arr3), // 選項3 json
        spec_item: JSON.stringify(spec_item), // 規格項目 json
        img_arr: JSON.stringify(this.img_arr), // img json
        type_arr: JSON.stringify(this.selectedCollectionsArr), // 分類 json
        tag_arr: JSON.stringify(tag_arr), // 標籤 json
        manu_arr: JSON.stringify(manu_arr), // 供應商 json
      }
      if (this.isNewProduct) {
        url = 'front/store/product/setProduct.php'
      } else {
        url = 'front/store/product/uProduct.php'
        data.spno = this.$route.params.id
      }
      this.$axios({
        url: url,
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        // console.log('新增/編輯商品', res)
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()

          this.$myVS.openNoti(res.data.Message, '', undefined)

          // 若是新增
          if (this.isNewProduct) {
            this.alertAfterCreated = true // 呈現alert
            this.alertTitle = this.title
            this.$router.replace({
              // 跳轉到新頁面
              name: 'edit-product',
              params: {
                id: res.data.Data.spno,
              },
            })
          } else {
            this.loadProduct()
          }
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 3.1.1.5 商品-刪除
    deleteProduct() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteProductBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/product/dProduct.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close() // 關閉按鈕loading
          this.deleteProduct_dialog = false // 關閉dialog
          this.$myVS.openNoti(res.data.Message, '', undefined)

          this.$router.replace({
            name: 'products',
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },

    // 3.1.1.8 商品-複製
    copy() {
      const loading = this.$vs.loading({
        target: this.$refs.copyBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/product/copyProduct.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spno: this.$route.params.id,
        },
      }).then((res) => {
        // console.log(res)
        if (res.data.Success) {
          this.copy_dialog = false // 關閉dialog
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.$router.push({
            name: 'products',
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        loading.close() // 關閉按鈕loading
      })
    },
    // 新增更多規格按鈕
    addOption() {
      let optionsTitle = this.optionsTitle
      let optionsValue = this.optionsValue
      if (optionsTitle.length >= 3) return

      // 新增規格
      if (optionsTitle.length === 0 || !optionsTitle.includes('顏色')) {
        optionsTitle.push('顏色')
      } else if (optionsTitle.length === 1 || !optionsTitle.includes('尺寸')) {
        optionsTitle.push('尺寸')
      } else if (optionsTitle.length === 2 || !optionsTitle.includes('材質')) {
        optionsTitle.push('材質')
      }
      // 新增選項
      optionsValue.push({
        values: [],
        input: '',
      })
    },
    // 刪除規格
    deleteOption(index) {
      let optionsTitle = this.optionsTitle
      let optionsValue = this.optionsValue
      // 刪除選中的index
      optionsTitle.splice(index, 1) // 刪除規格
      optionsValue.splice(index, 1) // 刪除選項

      this.deleteFunc() // 刪除規格細項

      // 若刪除後沒有任何規格，把規格關閉
      if (optionsTitle.length === 0) {
        this.options = false
      }
    },
    // 新增規格選項
    addValue(index1) {
      // 輸入框的值
      let value = this.optionsValue[index1].input
      // 值為空或是大於20字，return
      if (value === '' || value.length > 20) return
      if (value.replace(/(^\s*)|(\s*$)/g, '') === '') return
      if (this.optionsValue[index1].values.includes(value)) {
        this.$myVS.openNoti('發生錯誤', '此選項已經存在', 'danger', 3000)
        return
      }
      // 加入陣列中
      this.optionsValue[index1].values.push(value)

      // 第一個新增的選項，全部重整
      if (this.optionsValue[index1].values.length === 1) {
        this.optionsList = []
        if (index1 === 0) {
          if (this.optionsValue[1] && this.optionsValue[2]) {
            // 如果其他兩個規格都有值
            if (this.optionsValue[1].values.length && this.optionsValue[2].values.length) {
              // 若有三個規格
              this.optionsValue[index1].values.forEach((item0) => {
                this.optionsValue[1].values.forEach((item1) => {
                  this.optionsValue[2].values.forEach((item2) => {
                    this.optionsList.push({
                      title: item0, // 輸入框的值
                      arr2: {
                        title: item1,
                        inventory: '', // 庫存
                        salePrice: '', // 售價
                        originalPrice: '', // 原價
                        costPrice: '', // 成本價
                        sku: '', // 商品貨號
                        barcode: '', // 商品條碼
                        active: true,
                        arr3: {
                          title: item2,
                          inventory: '', // 庫存
                          salePrice: '', // 售價
                          originalPrice: '', // 原價
                          costPrice: '', // 成本價
                          sku: '', // 商品貨號
                          barcode: '', // 商品條碼
                          active: true,
                        },
                      },
                      inventory: '', // 庫存
                      salePrice: '', // 售價
                      originalPrice: '', // 原價
                      costPrice: '', // 成本價
                      sku: '', // 商品貨號
                      barcode: '', // 商品條碼
                      active: true,
                    })
                  })
                })
              })
            } else if (this.optionsValue[1].values.length) {
              // 如果第一個規格有值

              this.optionsValue[index1].values.forEach((item0) => {
                this.optionsValue[1].values.forEach((item1) => {
                  this.optionsList.push({
                    title: item0, // 輸入框的值
                    arr2: {
                      title: item1,
                      inventory: '', // 庫存
                      salePrice: '', // 售價
                      originalPrice: '', // 原價
                      costPrice: '', // 成本價
                      sku: '', // 商品貨號
                      barcode: '', // 商品條碼
                      active: true,
                      arr3: {},
                    },
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                  })
                })
              })
            } else if (this.optionsValue[2].values.length) {
              // 如果第三個規格有值

              this.optionsValue[index1].values.forEach((item0) => {
                this.optionsValue[2].values.forEach((item2) => {
                  this.optionsList.push({
                    title: item0, // 輸入框的值
                    arr2: {
                      title: item2,
                      inventory: '', // 庫存
                      salePrice: '', // 售價
                      originalPrice: '', // 原價
                      costPrice: '', // 成本價
                      sku: '', // 商品貨號
                      barcode: '', // 商品條碼
                      active: true,
                      arr3: {},
                    },
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                  })
                })
              })
            } else {
              // 如果其他兩個規格都沒有值
              this.optionsList.push({
                title: value, // 輸入框的值
                arr2: {},
                inventory: '', // 庫存
                salePrice: '', // 售價
                originalPrice: '', // 原價
                costPrice: '', // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
              })
            }
          } else if (this.optionsValue[1] && this.optionsValue[1].values.length) {
            // 若有第二個規格並且有選項

            this.optionsValue[index1].values.forEach((item0) => {
              this.optionsValue[1].values.forEach((item1) => {
                this.optionsList.push({
                  title: item0, // 輸入框的值
                  arr2: {
                    title: item1,
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                    arr3: {},
                  },
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                })
              })
            })
          } else if (this.optionsValue[2] && this.optionsValue[2].values.length) {
            // 若有第三個規格並且有選項

            this.optionsValue[index1].values.forEach((item0) => {
              this.optionsValue[2].values.forEach((item2) => {
                this.optionsList.push({
                  title: item0, // 輸入框的值
                  arr2: {
                    title: item2,
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                    arr3: {},
                  },
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                })
              })
            })
          } else {
            // 若只有一個規格

            this.optionsList.push({
              title: value, // 輸入框的值
              arr2: {},
              inventory: '', // 庫存
              salePrice: '', // 售價
              originalPrice: '', // 原價
              costPrice: '', // 成本價
              sku: '', // 商品貨號
              barcode: '', // 商品條碼
              active: true,
            })
          }
        }

        if (index1 === 1) {
          // 若不是第一次新增的選項
          if (this.optionsValue[0] && this.optionsValue[2]) {
            // 如果其他兩個規格都有值
            if (this.optionsValue[0].values.length && this.optionsValue[2].values.length) {
              // 若有三個規格
              this.optionsValue[index1].values.forEach((item0, index01) => {
                this.optionsValue[0].values.forEach((item1, index01) => {
                  this.optionsValue[2].values.forEach((item2, index02) => {
                    this.optionsList.push({
                      title: item1, // 輸入框的值
                      arr2: {
                        title: item0,
                        inventory: '', // 庫存
                        salePrice: '', // 售價
                        originalPrice: '', // 原價
                        costPrice: '', // 成本價
                        sku: '', // 商品貨號
                        barcode: '', // 商品條碼
                        active: true,
                        arr3: {
                          title: item2,
                          inventory: '', // 庫存
                          salePrice: '', // 售價
                          originalPrice: '', // 原價
                          costPrice: '', // 成本價
                          sku: '', // 商品貨號
                          barcode: '', // 商品條碼
                          active: true,
                        },
                      },
                      inventory: '', // 庫存
                      salePrice: '', // 售價
                      originalPrice: '', // 原價
                      costPrice: '', // 成本價
                      sku: '', // 商品貨號
                      barcode: '', // 商品條碼
                      active: true,
                    })
                  })
                })
              })
            } else if (this.optionsValue[0].values.length) {
              // 如果第一個規格有值

              this.optionsValue[index1].values.forEach((item1) => {
                this.optionsValue[0].values.forEach((item0, index01) => {
                  this.optionsList.push({
                    title: item0, // 輸入框的值
                    arr2: {
                      title: item1,
                      inventory: '', // 庫存
                      salePrice: '', // 售價
                      originalPrice: '', // 原價
                      costPrice: '', // 成本價
                      sku: '', // 商品貨號
                      barcode: '', // 商品條碼
                      active: true,
                      arr3: {},
                    },
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                  })
                })
              })
            } else if (this.optionsValue[2].values.length) {
              // 如果第三個規格有值

              this.optionsValue[index1].values.forEach((item1) => {
                this.optionsValue[2].values.forEach((item2, index01) => {
                  this.optionsList.push({
                    title: item1, // 輸入框的值
                    arr2: {
                      title: item2,
                      inventory: '', // 庫存
                      salePrice: '', // 售價
                      originalPrice: '', // 原價
                      costPrice: '', // 成本價
                      sku: '', // 商品貨號
                      barcode: '', // 商品條碼
                      active: true,
                      arr3: {},
                    },
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                  })
                })
              })
            } else {
              // 如果其他兩個規格都沒有值
              this.optionsList.push({
                title: value, // 輸入框的值
                arr2: {},
                inventory: '', // 庫存
                salePrice: '', // 售價
                originalPrice: '', // 原價
                costPrice: '', // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
              })
            }
          } else if (this.optionsValue[0] && this.optionsValue[0].values.length) {
            // 若有第一個規格並且有選項

            this.optionsValue[index1].values.forEach((item1) => {
              this.optionsValue[0].values.forEach((item0, index01) => {
                this.optionsList.push({
                  title: item0, // 輸入框的值
                  arr2: {
                    title: item1,
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                    arr3: {},
                  },
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                })
              })
            })
          } else if (this.optionsValue[2] && this.optionsValue[2].values.length) {
            // 若有第三個規格並且有選項

            this.optionsValue[index1].values.forEach((item1) => {
              this.optionsValue[2].values.forEach((item2, index01) => {
                this.optionsList.push({
                  title: item2, // 輸入框的值
                  arr2: {
                    title: item1,
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                    arr3: {},
                  },
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                })
              })
            })
          } else {
            // 若只有一個規格

            this.optionsList.push({
              title: value, // 輸入框的值
              arr2: {},
              inventory: '', // 庫存
              salePrice: '', // 售價
              originalPrice: '', // 原價
              costPrice: '', // 成本價
              sku: '', // 商品貨號
              barcode: '', // 商品條碼
              active: true,
            })
          }
        }

        if (index1 === 2) {
          // 若不是第一次新增的選項
          if (this.optionsValue[0] && this.optionsValue[1]) {
            // 如果其他兩個規格都有值
            if (this.optionsValue[0].values.length && this.optionsValue[1].values.length) {
              // 若有三個規格
              this.optionsValue[index1].values.forEach((item2) => {
                this.optionsValue[0].values.forEach((item0) => {
                  this.optionsValue[1].values.forEach((item1) => {
                    this.optionsList.push({
                      title: item0, // 輸入框的值
                      arr2: {
                        title: item1,
                        inventory: '', // 庫存
                        salePrice: '', // 售價
                        originalPrice: '', // 原價
                        costPrice: '', // 成本價
                        sku: '', // 商品貨號
                        barcode: '', // 商品條碼
                        active: true,
                        arr3: {
                          title: item2,
                          inventory: '', // 庫存
                          salePrice: '', // 售價
                          originalPrice: '', // 原價
                          costPrice: '', // 成本價
                          sku: '', // 商品貨號
                          barcode: '', // 商品條碼
                          active: true,
                        },
                      },
                      inventory: '', // 庫存
                      salePrice: '', // 售價
                      originalPrice: '', // 原價
                      costPrice: '', // 成本價
                      sku: '', // 商品貨號
                      barcode: '', // 商品條碼
                      active: true,
                    })
                  })
                })
              })
            } else if (this.optionsValue[0].values.length) {
              // 如果第一個規格有值

              this.optionsValue[index1].values.forEach((item2) => {
                this.optionsValue[0].values.forEach((item0) => {
                  this.optionsList.push({
                    title: item0, // 輸入框的值
                    arr2: {
                      title: item2,
                      inventory: '', // 庫存
                      salePrice: '', // 售價
                      originalPrice: '', // 原價
                      costPrice: '', // 成本價
                      sku: '', // 商品貨號
                      barcode: '', // 商品條碼
                      active: true,
                      arr3: {},
                    },
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                  })
                })
              })
            } else if (this.optionsValue[1].values.length) {
              // 如果第三個規格有值

              this.optionsValue[index1].values.forEach((item0) => {
                this.optionsValue[1].values.forEach((item1) => {
                  this.optionsList.push({
                    title: item0, // 輸入框的值
                    arr2: {
                      title: item1,
                      inventory: '', // 庫存
                      salePrice: '', // 售價
                      originalPrice: '', // 原價
                      costPrice: '', // 成本價
                      sku: '', // 商品貨號
                      barcode: '', // 商品條碼
                      active: true,
                      arr3: {},
                    },
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                  })
                })
              })
            } else {
              // 如果其他兩個規格都沒有值
              this.optionsList.push({
                title: value, // 輸入框的值
                arr2: {},
                inventory: '', // 庫存
                salePrice: '', // 售價
                originalPrice: '', // 原價
                costPrice: '', // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
              })
            }
          } else if (this.optionsValue[0] && this.optionsValue[0].values.length) {
            // 若有第一個規格並且有選項

            this.optionsValue[index1].values.forEach((item2) => {
              this.optionsValue[0].values.forEach((item0, index01) => {
                this.optionsList.push({
                  title: item0, // 輸入框的值
                  arr2: {
                    title: item2,
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                    arr3: {},
                  },
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                })
              })
            })
          } else if (this.optionsValue[1] && this.optionsValue[1].values.length) {
            // 若有第三個規格並且有選項

            this.optionsValue[index1].values.forEach((item2) => {
              this.optionsValue[1].values.forEach((item1) => {
                this.optionsList.push({
                  title: item1, // 輸入框的值
                  arr2: {
                    title: item2,
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                    arr3: {},
                  },
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                })
              })
            })
          } else {
            // 若只有一個規格

            this.optionsList.push({
              title: value, // 輸入框的值
              arr2: {},
              inventory: '', // 庫存
              salePrice: '', // 售價
              originalPrice: '', // 原價
              costPrice: '', // 成本價
              sku: '', // 商品貨號
              barcode: '', // 商品條碼
              active: true,
            })
          }
        }
      } else if (this.optionsValue[index1].values.length > 1) {
        if (index1 === 0) {
          if (this.optionsValue[1] && this.optionsValue[2]) {
            // 如果其他兩個規格都有值
            if (this.optionsValue[1].values.length && this.optionsValue[2].values.length) {
              // 若有三個規格
              this.optionsValue[1].values.forEach((item1) => {
                this.optionsValue[2].values.forEach((item2) => {
                  this.optionsList.push({
                    title: value, // 輸入框的值
                    arr2: {
                      title: item1,
                      inventory: '', // 庫存
                      salePrice: '', // 售價
                      originalPrice: '', // 原價
                      costPrice: '', // 成本價
                      sku: '', // 商品貨號
                      barcode: '', // 商品條碼
                      active: true,
                      arr3: {
                        title: item2,
                        inventory: '', // 庫存
                        salePrice: '', // 售價
                        originalPrice: '', // 原價
                        costPrice: '', // 成本價
                        sku: '', // 商品貨號
                        barcode: '', // 商品條碼
                        active: true,
                      },
                    },
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                  })
                })
              })
            } else if (this.optionsValue[1].values.length) {
              // 如果第一個規格有值

              this.optionsValue[1].values.forEach((item1) => {
                this.optionsList.push({
                  title: value, // 輸入框的值
                  arr2: {
                    title: item1,
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                    arr3: {},
                  },
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                })
              })
            } else if (this.optionsValue[2].values.length) {
              // 如果第三個規格有值

              this.optionsValue[2].values.forEach((item2) => {
                this.optionsList.push({
                  title: value, // 輸入框的值
                  arr2: {
                    title: item2,
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                    arr3: {},
                  },
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                })
              })
            } else {
              // 如果其他兩個規格都沒有值
              this.optionsList.push({
                title: value, // 輸入框的值
                arr2: {},
                inventory: '', // 庫存
                salePrice: '', // 售價
                originalPrice: '', // 原價
                costPrice: '', // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
              })
            }
          } else if (this.optionsValue[1] && this.optionsValue[1].values.length) {
            // 若有第二個規格並且有選項
            this.optionsValue[1].values.forEach((item1) => {
              this.optionsList.push({
                title: value, // 輸入框的值
                arr2: {
                  title: item1,
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                  arr3: {},
                },
                inventory: '', // 庫存
                salePrice: '', // 售價
                originalPrice: '', // 原價
                costPrice: '', // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
              })
            })
          } else if (this.optionsValue[2] && this.optionsValue[2].values.length) {
            // 若有第三個規格並且有選項
            this.optionsValue[2].values.forEach((item2) => {
              this.optionsList.push({
                title: value, // 輸入框的值
                arr2: {
                  title: item2,
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                  arr3: {},
                },
                inventory: '', // 庫存
                salePrice: '', // 售價
                originalPrice: '', // 原價
                costPrice: '', // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
              })
            })
          } else {
            // 若只有一個規格
            this.optionsList.push({
              title: value, // 輸入框的值
              arr2: {},
              inventory: '', // 庫存
              salePrice: '', // 售價
              originalPrice: '', // 原價
              costPrice: '', // 成本價
              sku: '', // 商品貨號
              barcode: '', // 商品條碼
              active: true,
            })
          }
        }

        if (index1 === 1) {
          // 若不是第一次新增的選項
          if (this.optionsValue[0] && this.optionsValue[2]) {
            // 如果其他兩個規格都有值
            if (this.optionsValue[0].values.length && this.optionsValue[2].values.length) {
              // 若有三個規格
              this.optionsValue[0].values.forEach((item1) => {
                this.optionsValue[2].values.forEach((item2) => {
                  this.optionsList.push({
                    title: item1, // 輸入框的值
                    arr2: {
                      title: value,
                      inventory: '', // 庫存
                      salePrice: '', // 售價
                      originalPrice: '', // 原價
                      costPrice: '', // 成本價
                      sku: '', // 商品貨號
                      barcode: '', // 商品條碼
                      active: true,
                      arr3: {
                        title: item2,
                        inventory: '', // 庫存
                        salePrice: '', // 售價
                        originalPrice: '', // 原價
                        costPrice: '', // 成本價
                        sku: '', // 商品貨號
                        barcode: '', // 商品條碼
                        active: true,
                      },
                    },
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                  })
                })
              })
            } else if (this.optionsValue[0].values.length) {
              // 如果第一個規格有值

              this.optionsValue[0].values.forEach((item0) => {
                this.optionsList.push({
                  title: item0, // 輸入框的值
                  arr2: {
                    title: value,
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                    arr3: {},
                  },
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                })
              })
            } else if (this.optionsValue[2].values.length) {
              // 如果第三個規格有值

              this.optionsValue[2].values.forEach((item2) => {
                this.optionsList.push({
                  title: value, // 輸入框的值
                  arr2: {
                    title: item2,
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                    arr3: {},
                  },
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                })
              })
            } else {
              // 如果其他兩個規格都沒有值
              this.optionsList.push({
                title: value, // 輸入框的值
                arr2: {},
                inventory: '', // 庫存
                salePrice: '', // 售價
                originalPrice: '', // 原價
                costPrice: '', // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
              })
            }
          } else if (this.optionsValue[0] && this.optionsValue[0].values.length) {
            // 若有第一個規格並且有選項

            this.optionsValue[0].values.forEach((item0) => {
              this.optionsList.push({
                title: item0, // 輸入框的值
                arr2: {
                  title: value,
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                  arr3: {},
                },
                inventory: '', // 庫存
                salePrice: '', // 售價
                originalPrice: '', // 原價
                costPrice: '', // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
              })
            })
          } else if (this.optionsValue[2] && this.optionsValue[2].values.length) {
            // 若有第三個規格並且有選項

            this.optionsValue[2].values.forEach((item2) => {
              this.optionsList.push({
                title: item2, // 輸入框的值
                arr2: {
                  title: value,
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                  arr3: {},
                },
                inventory: '', // 庫存
                salePrice: '', // 售價
                originalPrice: '', // 原價
                costPrice: '', // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
              })
            })
          } else {
            // 若只有一個規格

            this.optionsList.push({
              title: value, // 輸入框的值
              arr2: {},
              inventory: '', // 庫存
              salePrice: '', // 售價
              originalPrice: '', // 原價
              costPrice: '', // 成本價
              sku: '', // 商品貨號
              barcode: '', // 商品條碼
              active: true,
            })
          }
        }

        if (index1 === 2) {
          // 若不是第一次新增的選項
          if (this.optionsValue[0] && this.optionsValue[1]) {
            // 如果其他兩個規格都有值
            if (this.optionsValue[0].values.length && this.optionsValue[1].values.length) {
              // 若有三個規格
              this.optionsValue[0].values.forEach((item0) => {
                this.optionsValue[1].values.forEach((item1) => {
                  this.optionsList.push({
                    title: item0, // 輸入框的值
                    arr2: {
                      title: item1,
                      inventory: '', // 庫存
                      salePrice: '', // 售價
                      originalPrice: '', // 原價
                      costPrice: '', // 成本價
                      sku: '', // 商品貨號
                      barcode: '', // 商品條碼
                      active: true,
                      arr3: {
                        title: value,
                        inventory: '', // 庫存
                        salePrice: '', // 售價
                        originalPrice: '', // 原價
                        costPrice: '', // 成本價
                        sku: '', // 商品貨號
                        barcode: '', // 商品條碼
                        active: true,
                      },
                    },
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                  })
                })
              })
            } else if (this.optionsValue[0].values.length) {
              // 如果第一個規格有值

              this.optionsValue[0].values.forEach((item0) => {
                this.optionsList.push({
                  title: item0, // 輸入框的值
                  arr2: {
                    title: value,
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                    arr3: {},
                  },
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                })
              })
            } else if (this.optionsValue[1].values.length) {
              // 如果第三個規格有值

              this.optionsValue[1].values.forEach((item1) => {
                this.optionsList.push({
                  title: item1, // 輸入框的值
                  arr2: {
                    title: value,
                    inventory: '', // 庫存
                    salePrice: '', // 售價
                    originalPrice: '', // 原價
                    costPrice: '', // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                    arr3: {},
                  },
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                })
              })
            } else {
              // 如果其他兩個規格都沒有值
              this.optionsList.push({
                title: value, // 輸入框的值
                arr2: {},
                inventory: '', // 庫存
                salePrice: '', // 售價
                originalPrice: '', // 原價
                costPrice: '', // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
              })
            }
          } else if (this.optionsValue[0] && this.optionsValue[0].values.length) {
            // 若有第一個規格並且有選項

            this.optionsValue[0].values.forEach((item0) => {
              this.optionsList.push({
                title: item0, // 輸入框的值
                arr2: {
                  title: value,
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                  arr3: {},
                },
                inventory: '', // 庫存
                salePrice: '', // 售價
                originalPrice: '', // 原價
                costPrice: '', // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
              })
            })
          } else if (this.optionsValue[1] && this.optionsValue[1].values.length) {
            // 若有第三個規格並且有選項

            this.optionsValue[1].values.forEach((item1) => {
              this.optionsList.push({
                title: item1, // 輸入框的值
                arr2: {
                  title: value,
                  inventory: '', // 庫存
                  salePrice: '', // 售價
                  originalPrice: '', // 原價
                  costPrice: '', // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                  arr3: {},
                },
                inventory: '', // 庫存
                salePrice: '', // 售價
                originalPrice: '', // 原價
                costPrice: '', // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
              })
            })
          } else {
            // 若只有一個規格

            this.optionsList.push({
              title: value, // 輸入框的值
              arr2: {},
              inventory: '', // 庫存
              salePrice: '', // 售價
              originalPrice: '', // 原價
              costPrice: '', // 成本價
              sku: '', // 商品貨號
              barcode: '', // 商品條碼
              active: true,
            })
          }
        }
      }

      // 輸入框清空
      this.optionsValue[index1].input = ''
    },
    // 刪除規格選項
    deleteValue(index1, index2) {
      let deleteVal = this.optionsValue[index1].values[index2] // 取得被刪除的名稱
      this.optionsValue[index1].values.splice(index2, 1) // 刪除選項

      if (this.optionsValue[index1].values.length > 0) {
        if (index1 === 0) {
          this.optionsList = this.optionsList.filter(function (item) {
            return item.title != deleteVal
          })
        } else if (index1 === 1) {
          this.optionsList = this.optionsList.filter(function (item) {
            return item.arr2.title != deleteVal
          })
        } else if (index1 === 2) {
          this.optionsList = this.optionsList.filter(function (item) {
            return item.arr2.arr3.title != deleteVal
          })
        }
      } else {
        this.deleteFunc()
      }
    },
    // 刪除規格細項
    deleteFunc() {
      let num = ''
      this.optionsList = []
      if (this.optionsValue[0] && this.optionsValue[0].values.length) num = '1'
      if (this.optionsValue[1] && this.optionsValue[1].values.length) num += '2'
      if (this.optionsValue[2] && this.optionsValue[2].values.length) num += '3'
      // 三個選項都有值
      if (num === '123') {
        this.optionsValue[0].values.forEach((item0) => {
          this.optionsValue[1].values.forEach((item1) => {
            this.optionsValue[2].values.forEach((item2) => {
              this.optionsList.push({
                title: item0, // 輸入框的值
                arr2: {
                  title: item1,
                  inventory: 0, // 庫存
                  salePrice: 0, // 售價
                  originalPrice: 0, // 原價
                  costPrice: 0, // 成本價
                  sku: '', // 商品貨號
                  barcode: '', // 商品條碼
                  active: true,
                  arr3: {
                    title: item2,
                    inventory: 0, // 庫存
                    salePrice: 0, // 售價
                    originalPrice: 0, // 原價
                    costPrice: 0, // 成本價
                    sku: '', // 商品貨號
                    barcode: '', // 商品條碼
                    active: true,
                  },
                },
                inventory: 0, // 庫存
                salePrice: 0, // 售價
                originalPrice: 0, // 原價
                costPrice: 0, // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
              })
            })
          })
        })
      } else if (num === '12') {
        this.optionsValue[0].values.forEach((item0) => {
          this.optionsValue[1].values.forEach((item1) => {
            this.optionsList.push({
              title: item0, // 輸入框的值
              arr2: {
                title: item1,
                inventory: 0, // 庫存
                salePrice: 0, // 售價
                originalPrice: 0, // 原價
                costPrice: 0, // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
                arr3: {},
              },
              inventory: 0, // 庫存
              salePrice: 0, // 售價
              originalPrice: 0, // 原價
              costPrice: 0, // 成本價
              sku: '', // 商品貨號
              barcode: '', // 商品條碼
              active: true,
            })
          })
        })
      } else if (num === '13') {
        this.optionsValue[0].values.forEach((item0) => {
          this.optionsValue[2].values.forEach((item2) => {
            this.optionsList.push({
              title: item0, // 輸入框的值
              arr2: {
                title: item2,
                inventory: 0, // 庫存
                salePrice: 0, // 售價
                originalPrice: 0, // 原價
                costPrice: 0, // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
                arr3: {},
              },
              inventory: 0, // 庫存
              salePrice: 0, // 售價
              originalPrice: 0, // 原價
              costPrice: 0, // 成本價
              sku: '', // 商品貨號
              barcode: '', // 商品條碼
              active: true,
            })
          })
        })
      } else if (num === '23') {
        this.optionsValue[1].values.forEach((item1) => {
          this.optionsValue[2].values.forEach((item2) => {
            this.optionsList.push({
              title: item1, // 輸入框的值
              arr2: {
                title: item2,
                inventory: 0, // 庫存
                salePrice: 0, // 售價
                originalPrice: 0, // 原價
                costPrice: 0, // 成本價
                sku: '', // 商品貨號
                barcode: '', // 商品條碼
                active: true,
                arr3: {},
              },
              inventory: 0, // 庫存
              salePrice: 0, // 售價
              originalPrice: 0, // 原價
              costPrice: 0, // 成本價
              sku: '', // 商品貨號
              barcode: '', // 商品條碼
              active: true,
            })
          })
        })
      } else if (num === '1') {
        this.optionsValue[0].values.forEach((item0) => {
          this.optionsList.push({
            title: item0, // 輸入框的值
            arr2: {},
            inventory: 0, // 庫存
            salePrice: 0, // 售價
            originalPrice: 0, // 原價
            costPrice: 0, // 成本價
            sku: '', // 商品貨號
            barcode: '', // 商品條碼
            active: true,
          })
        })
      } else if (num === '2') {
        this.optionsValue[1].values.forEach((item1) => {
          this.optionsList.push({
            title: item1, // 輸入框的值
            arr2: {},
            inventory: 0, // 庫存
            salePrice: 0, // 售價
            originalPrice: 0, // 原價
            costPrice: 0, // 成本價
            sku: '', // 商品貨號
            barcode: '', // 商品條碼
            active: true,
          })
        })
      } else if (num === '3') {
        this.optionsValue[2].values.forEach((item2) => {
          this.optionsList.push({
            title: item2, // 輸入框的值
            arr2: {},
            inventory: 0, // 庫存
            salePrice: 0, // 售價
            originalPrice: 0, // 原價
            costPrice: 0, // 成本價
            sku: '', // 商品貨號
            barcode: '', // 商品條碼
            active: true,
          })
        })
      }
    },
    // 規格細項批次修改
    bathEdit() {
      let newOptionsList = this.optionsList // 傳址

      // 迴圈有被打勾的欄位
      Object.keys(this.bathCheckbox).forEach((key) => {
        // 若被打勾
        if (this.bathCheckbox[key]) {
          // 迴圈規格細項列表，把同個key的值更改為input的值
          newOptionsList.forEach((item) => {
            item[key] = this.bathInput[key]
          })
        }
      })

      this.bathDialog = false // 關閉 dialog
      Object.keys(this.bathCheckbox).forEach((key) => {
        this.bathCheckbox[key] = false // checkbox 重整
        key === 'active' ? (this.bathInput[key] = false) : (this.bathInput[key] = '') // input重整
      })
    },
    // 3.1.4.1 讀取標籤列表
    getTagList() {
      return this.$axios({
        url: 'front/store/tag/getTagList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.tags = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        return res.data.Success
      })
    },
    // 3.1.4.3 標籤資料-新增
    newTag() {
      if (!this.newTagTitle) return
      const loading = this.$vs.loading({
        target: this.$refs.newTagBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/tag/setTagData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          title: this.newTagTitle,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.newTagTitle = ''
          this.tagTooltip = false
          loading.close()
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.tags = []
          this.getTagList()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 3.1.5.1 讀取廠商列表
    getVendorList() {
      return this.$axios({
        url: 'front/store/manufacturer/getManufacturerList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.vendors = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
        return res.data.Success
      })
    },
    // 3.1.5.3 廠商資料-新增
    newVendor() {
      if (!this.newVendorTitle) return
      const loading = this.$vs.loading({
        target: this.$refs.newVendorBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/manufacturer/setManufacturerData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          title: this.newVendorTitle,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close()
          this.newVendorTitle = ''
          this.vendorTooltip = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.vendors = []
          this.getVendorList()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 3.1.3.1 讀取分類列表
    getCollections() {
      return this.$axios({
        url: 'front//store/type/getTypeList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.collections = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        return res.data.Success
      })
    },

    // 3.1.3.3 分類資料-新增
    newCollection() {
      const loading = this.$vs.loading({
        target: this.$refs.newCollectionBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/type/setTypeData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          title: this.newCollectionTitle,
          img: '',
          cont: '',
          mtitle: this.newCollectionTitle,
          mdescs: '',
          mpages: this.newCollectionTitle.replace(/[&|\*^'"{}[\]%$:;=#@!/?\s*\\\\<>,.()~+_`]/g, '-'),
        },
      }).then((res) => {
        if (res.data.Success) {
          this.getCollections()
          loading.close()
          this.collectionTooltip = false
          this.newCollectionTitle = ''
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 分類標籤刪除時
    deleteCollectionTag(item) {
      const sptno = item.sptno

      let level1 = ''
      //刪除 json
      this.selectedCollectionsArr.forEach((arr1, index1) => {
        if (arr1.sptno === sptno) {
          // console.log('刪除的在第一層')
          level1 = arr1
          this.selectedCollectionsArr.splice(index1, 1)

          // 刪除第二層的tag
          arr1.arr.forEach((arr2) => {
            this.selectedCollections = this.selectedCollections.filter((item2) => item2 !== arr2.sptno)

            // 刪除第三層的tag
            arr2.arr.forEach((arr3) => {
              this.selectedCollections = this.selectedCollections.filter((item3) => item3 !== arr3.sptno)
            })
          })
        } else {
          arr1.arr.forEach((arr2, index2) => {
            if (arr2.sptno === sptno) {
              // console.log('刪除的在第二層')
              this.selectedCollectionsArr[index1].arr.splice(index2, 1)

              // 刪除第三層的tag
              arr2.arr.forEach((arr3) => {
                this.selectedCollections = this.selectedCollections.filter((item3) => item3 !== arr3.sptno)
              })
            } else {
              arr2.arr.forEach((arr3, index3) => {
                if (arr3.sptno === sptno) {
                  // console.log('刪除的在第三層')
                  this.selectedCollectionsArr[index1].arr[index2].arr.splice(index3, 1)
                }
              })
            }
          })
        }
      })

      //刪除自己tag
      this.selectedCollections = this.selectedCollections.filter((item) => item !== sptno)
    },
    checkbox(arr, e, arr1, arr2) {
      const check = e.target.checked
      const level = arr.level
      const sptno = arr.sptno

      // 勾選時
      if (check) {
        if (level === 1) {
          this.selectedCollectionsArr.push({
            sptno: arr.sptno,
            arr: [],
          })
        }

        if (level === 2) {
          // 控制v-model部分
          if (!this.selectedCollections.includes(arr1.sptno)) {
            this.selectedCollections.push(arr1.sptno)
          }
          //
          if (this.selectedCollectionsArr.length > 0) {
            let findIndex1 = undefined
            this.selectedCollectionsArr.forEach((item, index) => {
              // 若有第一層，新增進去
              if (item.sptno === arr1.sptno) {
                findIndex1 = index
                this.selectedCollectionsArr[index].arr.push({
                  sptno: arr.sptno,
                  arr: [],
                })
              }
            })

            if (findIndex1 === undefined) {
              // console.log('若沒有找到第一層,新增第一層+第二層')
              this.selectedCollectionsArr.push({
                sptno: arr1.sptno,
                arr: [
                  {
                    sptno: arr.sptno,
                    arr: [],
                  },
                ],
              })
            }
          } else {
            // console.log('新增第一層+第二層')
            this.selectedCollectionsArr.push({
              sptno: arr1.sptno,
              arr: [
                {
                  sptno: arr.sptno,
                  arr: [],
                },
              ],
            })
          }
        }

        if (level === 3) {
          // 控制v-model部分
          if (!this.selectedCollections.includes(arr1.sptno)) {
            this.selectedCollections.push(arr1.sptno)
          }
          if (!this.selectedCollections.includes(arr2.sptno)) {
            this.selectedCollections.push(arr2.sptno)
          }
          //
          if (this.selectedCollectionsArr.length > 0) {
            let findIndex1 = undefined
            let findIndex2 = undefined

            this.selectedCollectionsArr.forEach((item, index) => {
              // 若有第一層
              if (item.sptno === arr1.sptno) {
                findIndex1 = index
                if (this.selectedCollectionsArr[index].arr.length > 0) {
                  this.selectedCollectionsArr[index].arr.forEach((item2, index2) => {
                    // 若也有第二層，新增進去
                    if (item2.sptno === arr2.sptno) {
                      findIndex2 = index2
                      // console.log('若也有第二層，新增進去')
                      this.selectedCollectionsArr[index].arr[index2].arr.push({
                        sptno: arr.sptno,
                        arr: [],
                      })
                    }
                  })
                }
              }
            })

            // 若沒找到第一層，就新增一二三層
            if (findIndex1 === undefined) {
              // console.log('// 若沒找到第一層，就新增一二三層', findIndex1, findIndex2)
              this.selectedCollectionsArr.push({
                sptno: arr1.sptno,
                arr: [
                  {
                    sptno: arr2.sptno,
                    arr: [
                      {
                        sptno: arr.sptno,
                        arr: [],
                      },
                    ],
                  },
                ],
              })
            } else if (findIndex1 !== undefined && findIndex2 === undefined) {
              // console.log('有找到第一層但沒找到第二層', findIndex1, findIndex2)
              this.selectedCollectionsArr[findIndex1].arr.push({
                sptno: arr2.sptno,
                arr: [
                  {
                    sptno: arr.sptno,
                    arr: [],
                  },
                ],
              })
            }
          } else {
            this.selectedCollectionsArr.push({
              sptno: arr1.sptno,
              arr: [
                {
                  sptno: arr2.sptno,
                  arr: [
                    {
                      sptno: arr.sptno,
                      arr: [],
                    },
                  ],
                },
              ],
            })
          }
        }
        // 取消勾選時
      } else {
        if (level === 1) {
          // 控制v-model部分
          // 當有第二層
          if (arr.arr.length > 0) {
            arr.arr.forEach((arr2) => {
              this.selectedCollections = this.selectedCollections.filter((sptno) => {
                return sptno !== arr2.sptno
              })

              // 當有第三層
              if (arr2.arr.length > 0) {
                arr2.arr.forEach((arr3) => {
                  this.selectedCollections = this.selectedCollections.filter((sptno) => {
                    return sptno !== arr3.sptno
                  })
                })
              }
            })
          }
          //
          this.selectedCollectionsArr = this.selectedCollectionsArr.filter((item) => {
            return item.sptno !== arr.sptno
          })
        } else if (level === 2) {
          // 控制v-model部分
          // 當有第三層
          if (arr.arr.length > 0) {
            arr.arr.forEach((arr3) => {
              this.selectedCollections = this.selectedCollections.filter((sptno) => {
                return sptno !== arr3.sptno
              })
            })
          }
          //
          this.selectedCollectionsArr.forEach((item1, index1) => {
            if (item1.sptno === arr1.sptno) {
              this.selectedCollectionsArr[index1].arr = this.selectedCollectionsArr[index1].arr.filter((item2) => {
                return item2.sptno !== arr.sptno
              })
            }
          })
        } else if (level === 3) {
          this.selectedCollectionsArr.forEach((item1, index1) => {
            if (item1.sptno === arr1.sptno) {
              this.selectedCollectionsArr[index1].arr.forEach((item2, index2) => {
                if (item2.sptno === arr2.sptno) {
                  this.selectedCollectionsArr[index1].arr[index2].arr = this.selectedCollectionsArr[index1].arr[index2].arr.filter(
                    (item3) => {
                      return item3.sptno !== arr.sptno
                    }
                  )
                }
              })
            }
          })
        }
      }
    },
    uploadImg(e) {
      if (!e.target.files[0]) return
      if (e.target.files[0].type.indexOf('image') === -1) {
        this.$myVS.openNoti('發生錯誤，請稍候再試', '僅限圖片格式上傳', 'danger', 10000)
        return
      }

      const length = e.target.files.length

      for (let i = 0; i < length; i++) {
        const image = e.target.files[i]
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = (e) => {
          this.img_arr.push({
            img: e.target.result,
            alt: image.name,
          })
        }
      }

      e.target.value = ''
    },
    previewImg(index) {
      this.previewIndex = index // 預覽中的索引
      this.alt = this.img_arr[index].alt // alt 備份
      this.previewDialog = true
    },
    saveALT(invalid) {
      if (invalid) return
      this.img_arr[this.previewIndex].alt = this.alt
      this.previewDialog = false
    },
    previewClose() {
      this.alt = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-6;
}

.title {
  @apply text-sm text-gray-text_dark mb-1;
}

.upload {
  @apply border-2 rounded-xl border-dashed border-gray-200 overflow-hidden flex items-center justify-center hover:border-gray-divide transition-colors cursor-pointer relative;
  aspect-ratio: 1 / 1;
}

.image_wrap {
  @apply border-2 rounded-xl border-gray-200 overflow-hidden flex items-center justify-center relative;
  cursor: grab;
  aspect-ratio: 1 / 1;

  .image_func {
    @apply absolute
      left-0
      top-0
      w-full
      h-full
      bg-black bg-opacity-50
      transition-all
      flex
      items-center
      justify-center
      opacity-0
      hover:opacity-100;
  }
}

::v-deep #vs-input--noBorderInput {
  border: none;
  padding-left: 0px;
}

::v-deep .formCheckbox .vs-checkbox-label {
  padding-left: 16px;
}

.optionsDetail1 {
  width: 100px;
  margin-right: 12px;
  flex-shrink: 0;
}
.optionsDetail2 {
  width: 150px;
  margin-right: 12px;
  flex-shrink: 0;
}
.optionsDetail3 {
  width: 50px;
  flex-shrink: 0;
}

// 預覽圖片dialog
::v-deep .vs-dialog__content.notFooter {
  height: 100%;
}

.dragClass {
  background: white;
  opacity: 1 !important;
}

.chosenClass {
  cursor: grabbing !important;
}

.collectionsLevel2 {
  margin-left: 30px;
}
.collectionsLevel3 {
  margin-left: 30px;
}
</style>
